.platform-icon {
  height: 3em;
  width: 3em;
}

.paper-width {
  width: 20em;
}

.paper-margin {
  margin: 1em;
}

.platform-paper-div {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.platform-icon-div {
  display: grid;
  gap: 1em;
  grid-template-columns: auto auto;
  align-items: center;
}

.connect-div {
  display: grid;
  grid-template-rows: auto auto auto;
  gap: 1em;
}
