.table-head {
  font-family: Poppins;
  font-weight: bold;
}

.table-row {
  font-family: Poppins;
}

.table-element {
  text-align: center;
  border: 0.1em solid #31ab70;
}
