.line-div {
  display: flex;
  justify-content: space-between;
}

.panel-div {
  display: grid;
  gap: 1em;
  margin-top: 1em;
}
