.functions-div {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
}

.functions-column {
  overflow-y: scroll;
  width: 40vw;
  height: 40vh;
}
