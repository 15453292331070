.items-div {
  display: flex;
  justify-content: space-around;
  margin-top: 1em;
  border: 0.05em solid #4285f4;
  border-radius: 2em;
}

.items-column {
  overflow-y: scroll;
  width: 40vw;
  height: 40vh;
}
